.param-container[data-v-2f404496] {
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.param-container .param-label[data-v-2f404496] {
    width: 150px;
    text-align: right;
    padding-right: 10px;
}
.param-container .param-value[data-v-2f404496] {
    width: calc(100% - 160px);
}
